<template>
    <section class="s-categories">
        <div class="s-categories-text">
            <h3 class="f-st">Browse Collection</h3>
            <ul class="s-categories-list">
                <li class="s-categories-item" v-for="(col,index) in collection" :key="index">
                    <a class="s-categories-link" :href="'#/collection/'+ col.slug">{{col.name}}</a>
                    <div class="s-categories-images"> 
                        <div class="s-categories-image" v-if="col && col.images && col.images[0].image && col.images[0].image[0].src"
                            style="height: 226px; width: 160px; opacity: 1; transform: translate(100px, -260px);"> 
                            <img :src="$store.state.file_url + col.images[0].image[0].src + '?alt=media'" 
                            alt="Rino &amp; Pelle"> 
                        </div> 
                        <div class="s-categories-image" v-if="col && col.images && col.images[2].image && col.images[2].image[0].src"
                        style="height: 339px; width: 240px; opacity: 1; transform: translate(240px, -16px);"> 
                            <img :src="$store.state.file_url + col.images[2].image[0].src + '?alt=media'" 
                            alt="Rino &amp; Pelle"> 
                        </div> 
                        <div class="s-categories-image" v-if="col && col.images && col.images[1].image && col.images[1].image[0].src"
                        style="height: 454px; width: 320px; opacity: 1; transform: translate(1060px, -260px);"> 
                            <img :src="$store.state.file_url + col.images[1].image[0].src + '?alt=media'"  
                            alt="Rino &amp; Pelle"> 
                        </div>
                    </div>
                </li>
            </ul>
            <a class="f-bs" href="#/collection">
                <svg class="f-bs-arrow" viewBox="0 0 6 10">
                    <path
                        d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z" />
                </svg>
                <svg class="f-bs-arrow" viewBox="0 0 6 10">
                    <path
                        d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z" />
                </svg>
                <span class="f-bs-line"></span> <i class="f-bs-label">View everything</i>
            </a>
        </div>
        
    </section>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            collection: {}
        }
    },
    created(){
        this.init()
    },
    methods:{
        init(){
            axios
            .post(
                this.$store.state.api + "collection/_search",
                {
                from: 0,
                size: 5000,
                query: {
                bool: {
                  must: [
                    {
                      match_phrase: {
                        event: this.$store.state.event
                      }
                    },
                    {
                      match_phrase: {
                        has_parent: "No"
                      }
                    },
                    {
                      match_phrase: {
                        status: "Active"
                      }
                    }
                  ]
                }
              }
            }
            ).then(response => {
                this.collection = []
                var data = response.data.hits.hits
                for(var ind in data){
                    this.collection[ind] = data[ind]._source
                }
                // console.log(this.collection, 'collection')
                this.collection.sort((a, b) => {
                    return ( a.sort_no - b.sort_no);
                });
                // console.log(this.collection, 'collection sort ')
            })
        }
    }

}
</script>

<style>
.s-categories-link:hover:before{
    width: 3vw;
    content: '';
    height: 1px;
    margin-bottom: 2vw;
    margin-right: 0.2vw;
    display: inline-block;
    background: #ae97a2
}
.f-bs:hover .f-bs-arrow,
.f-bs:hover .f-bs-line{
    animation: right-left .5s ease-in-out forwards;
}
.f-bs:hover .f-bs-label{
    animation: left-right .5s ease-in-out forwards;
}
@keyframes right-left{
    0%{
        transform: translate(0px, 0px);
    }
    100%{
        transform: translate(95px, 0px);
    }
}
@keyframes left-right{
    0%{
        transform: translate(0px, 0px);
    }
    100%{
        transform: translate(-85px, 0px);
    }
}
.s-categories-image img {
    opacity: 0;
}
@keyframes come-in-fade {
  to { 
      transform: translateY(0); 
    }
    0%{ opacity: 0 }
    100%  { opacity: 1 }
}
.s-categories-item{
    z-index: 9;
}
.s-categories-link{
    z-index: 9;
}
.s-categories-item:hover  img{
    opacity: 0.1;
    /* transition: 1s all ease; */
    transform: translateY(200px);
    animation-delay: 5s;
    animation: come-in-fade 2s ease forwards;
}
</style>